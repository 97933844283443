import React from 'react';
import { TabletA7Lite, TabletPlusStand, TayariGrow, TayariPro, TayariStat, ThermoPrinter } from '../assets';
import Footer from '../components/Footer';

const Pricing = () => {
    return (
        <div className='h-full'>
            <section id='#' className='h-fit'>
                <div className='py-4 flex justify-center items-center'>
                    <div className='flex w-[1080px] max-md:w-full max-md:px-6 items-center justify-between'>
                        <div className='flex flex-col w-full'>
                            <div className='font-bold text-7xl max-sm:text-5xl my-3 w-[90%]'>Simple pricing that scales with your business</div>
                            <div className='font-normal text-xl my-4'>Three flexible plans. No hidden fees. Upgrade,downgrade or cancel your plan at any time.</div>
                            <div className='flex max-sm:flex-col max-md:flex-wrap w-full gap-5 my-5'>
                                <>
                                    <div className='bg-zinc-100 h-[600px] w-[30%] max-md:w-full px-2'>
                                        <img src={TayariStat} className='h-[50px] w-[50px] ' />
                                        <div className='text-3xl font-bold my-4'>TayariStart</div>
                                        <div className='text-[15px] h-[100px] w-[200px]'>Perfect for new restaurant, small backery, burger or juice shops</div>
                                        <div className='text-[#E65300] text-3xl font-bold my-1'>Tzs 500,000</div>
                                        <div className='mb-4'>6 Monthly fee Tzs 27000</div>
                                        <div className='h-[218px] w-[300px]'>
                                            <div className='flex flex-col gap-2'>✅ 1 User</div>
                                            <div className='flex flex-col gap-2'>✅ 1 Branch</div>
                                            <div className='flex flex-col gap-2'>✅ Access on POS, Mobile app</div>
                                            <div className='flex flex-col gap-2'>✅ Menu added on TAYARI app</div>
                                            <div className='flex flex-col gap-2'>✅ Report (Profit and sales)</div>
                                            <div className='flex flex-col gap-2'>✅ Inventory management</div>
                                            <div className='flex flex-col gap-2'>✅ Unlimited orders</div>
                                        </div>
                                        <div className='max-sm:mb-10 bg-[#042D5E] h-[50px] w-[120px] items-center justify-center text-center text-white font-semibold text-[16px] rounded-lg hover:bg-[#E65300] cursor-pointer mt-10 mb-4 flex'>Get Started</div>
                                    </div>
                                    <div className='w-[1px] bg-zinc-300 h-[600px] m-2 max-md:hidden' />
                                </>
                                <>
                                    <div className='bg-zinc-100 h-[600px] w-[30%] max-md:w-full px-2'>
                                        <img src={TayariGrow} className='h-[50px] w-[50px] ' />
                                        <div className='text-3xl font-bold my-4'>TayariGrow</div>
                                        <div className='text-[15px] h-[100px] w-[200px]'>Perfect for restaurants with established market and are looking to optimize sales and grow their business:</div>
                                        <div className='text-[#E65300] text-3xl font-bold my-1'>Tsh 700,000</div>
                                        <div className='mb-4'>6 Monthly fee Tzs 37000</div>
                                        <div className='h-[218px] w-[300px]'>
                                            <div className='flex flex-col gap-2'>✅ 6 Users</div>
                                            <div className='flex flex-col gap-2'>✅ 10 Waiters</div>
                                            <div className='flex flex-col gap-2'>✅ Access on POS, PC & Mobile App</div>
                                            <div className='flex flex-col gap-2'>✅ Menu added on TAYARI app</div>
                                            <div className='flex flex-col gap-2'>✅ Special QR code to receive orders</div>
                                            <div className='flex flex-col gap-2'>✅ Interact with customers through texts messages</div>
                                            <div className='flex flex-col gap-2'>✅ Inventory management</div>
                                            <div className='flex flex-col gap-2'>✅ Unlimited orders</div>
                                        </div>
                                        <div className='max-sm:mb-10 bg-[#042D5E] h-[50px] w-[120px] items-center justify-center text-center text-white font-semibold text-[16px] rounded-lg hover:bg-[#E65300] cursor-pointer mt-10 mb-4 flex'>Get Started</div>
                                    </div>
                                    <div className='w-[1px] bg-zinc-300 h-[600px] m-2 max-md:hidden' />
                                </>
                                <>
                                    <div className='bg-zinc-100 h-[600px] w-[30%] max-md:w-full px-2'>
                                        <img src={TayariPro} className='h-[50px] w-[50px] ' />
                                        <div className='text-3xl font-bold my-4'>TayariPro</div>
                                        <div className='text-[15px] h-[100px] w-[200px]'>Perfect for busy restaurants looking for a solution to easy order taking and provide better customer services:</div>
                                        <div className='text-[#E65300] text-3xl font-bold my-1'>Tsh 850,000</div>
                                        <div className='mb-4'>6 Monthly fee Tzs 47000</div>
                                        <div className='h-[218px] w-[300px]'>
                                            <div className='flex flex-col gap-2'>✅ Unlimited Users</div>
                                            <div className='flex flex-col gap-2'>✅ Unlimited Waiters</div>
                                            <div className='flex flex-col gap-2'>✅ Access on POS, PC & Mobile App</div>
                                            <div className='flex flex-col gap-2'>✅ Menu added on TAYARI app</div>
                                            <div className='flex flex-col gap-2'>✅ Special QR code to receive orders</div>
                                            <div className='flex flex-col gap-2'>✅ Interact with customers through texts messages</div>
                                            <div className='flex flex-col gap-2'>✅ Inventory management</div>
                                            <div className='flex flex-col gap-2'>✅ Unlimited orders</div>
                                        </div>
                                        <div className='max-sm:mb-10 bg-[#042D5E] h-[50px] w-[120px] items-center justify-center text-center text-white font-semibold text-[16px] rounded-lg hover:bg-[#E65300] cursor-pointer mt-10 mb-4 flex'>Get Started</div>
                                    </div>
                                </>
                            </div>
                            <div className='font-bold text-3xl my-3 max-md:mt-[50px] max-sm:mt-[100px] w-[90%]'>Device Price</div>
                            <div className='flex w-full gap-4 max-sm:gap-2 justify-between'>
                                <div className='h-[400px] max-sm:h-[450px] w-[45%] max-md:w-[48%] bg-orange-600 text-white rounded-2xl p-10 max-md:p-4'>
                                    <div className='leading-10 text-5xl font-bold'>
                                        <div className='max-sm:text-2xl'>Tablet</div>
                                        <div className='max-sm:text-2xl'>Installation</div>
                                    </div>
                                    <div className='my-10 max-md:h-[120px] max-sm:h-[220px] text-[18px] max-md:text-[16px] max-sm:text-[14px]'>
                                        <div className='flex flex-col gap-2'>✅ Tablet Samsung A7 lite = 470,000</div>
                                        <div className='flex flex-col gap-2'>✅ Printer: Wifi + Bluetooth 380,000</div>
                                        <div className='flex flex-col gap-2'>✅ Stand 70,000</div>
                                        <div className='flex flex-col gap-2'>✅ Cover 35,000</div>
                                    </div>
                                    <div className='text-white text-5xl max-sm:text-2xl font-bold my-1'>Tsh 850,000</div>
                                </div>
                                <div className='h-[400px] max-sm:h-[450px] w-[45%] max-md:w-[48%] bg-orange-600 text-white rounded-2xl p-10 max-md:p-4'>
                                    <div className='leading-10 text-5xl font-bold'>
                                        <div className='max-sm:text-2xl'>Tablet</div>
                                        <div className='max-sm:text-2xl'>Installation</div>
                                    </div>
                                    <div className='my-10 max-md:h-[120px] max-sm:h-[220px] text-[18px] max-md:text-[16px] max-sm:text-[14px]'>
                                        <div className='flex flex-col gap-2'>✅ 1 x POS Touch Monitor</div>
                                        <div className='flex flex-col gap-2'>✅ 1 x POS Thermal Receipt Printer (80mm)</div>
                                        <div className='flex flex-col gap-2'>✅ 1 x Desktop Barcode Scanner 2D</div>
                                        <div className='flex flex-col gap-2'>✅ 1 x Cash Register Drawer</div>
                                    </div>
                                    <div className='text-white text-5xl max-sm:text-2xl font-bold my-1'>Tzs 2,500,000</div>
                                </div>
                            </div>
                            <div className='flex w-full my-10 gap-4 justify-between'>
                                <div className='flex flex-col flex-shrink-0 h-[300px] max-md:h-fit w-[30%] bg-white rounded-2xl p-4 items-center justify-center'>
                                    <div className='text-3xl max-sm:text-base max-md:h-[40px] flex-shrink-0 font-bold text-center'>Tablet A7 Lite</div>
                                    <img src={TabletA7Lite} className='w-[200px] object-center mt-3' />
                                </div>
                                <div className='flex flex-col flex-shrink-0 h-[300px] max-md:h-fit w-[30%] bg-white rounded-2xl p-4 items-center justify-center'>
                                    <div className='text-3xl max-sm:text-base max-md:h-[40px] flex-shrink-0 font-bold text-center'>Tablet + Stand</div>
                                    <img src={TabletPlusStand} className='w-[200px] object-center mt-3' />
                                </div>
                                <div className='flex flex-col flex-shrink-0 h-[300px] max-md:h-fit w-[30%] bg-white rounded-2xl p-4 items-center justify-center'>
                                    <div className='text-3xl max-sm:text-base max-md:h-[40px] flex-shrink-0 font-bold text-center'>Thermo Printer</div>
                                    <img src={ThermoPrinter} className='w-[200px] object-center mt-3' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
};

export default Pricing;