import React from 'react'
import FAQ from '../components/FAQ'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'

const ContactUs = () => {
  return (
    <div className='h-full'>
      <section id='#' className='h-fit'>
        <div className='py-4 flex flex-col justify-center items-center bg-white'>
          <div className='flex flex-col w-[1080px] max-md:w-full max-md:px-6 items-center justify-between'>
            <div className='flex flex-col w-full'>
              <div className=''>
                <div className='font-bold leading-[50px] text-7xl max-md:text-5xl my-3 w-[90%] max-md:w-full'>Reach out with</div>
                <div className='font-bold leading-[50px] text-7xl max-md:text-5xl my-3 w-[90%] max-md:w-full'>any questions you have</div>
                <div className='font-normal text-xl my-10'>Please choose how you’d like to get help.</div>
                <div className='flex flex-col w-full gap-5'>
                  <div className='flex w-[80%] gap-5'>
                    <div className='flex justify-center items-center'>
                      <div className=''>I have a Tayari account, and I need support.</div>
                      <div className='w-[1px] bg-zinc-300 h-[100px] m-4' />
                    </div>
                    <div className='flex justify-center items-center'>
                      <div className=''>I have a Tayari account, and I am interested in more products.</div>
                      <div className='w-[1px] bg-zinc-300 h-[100px] m-4' />
                    </div>
                    <div className='flex justify-center items-center'>
                      <div className='flex'>I don’t have a Tayari account, and I have questions.</div>
                    </div>
                  </div>
                  <div className=''>
                    <div className='flex max-md:flex-col w-full justify-between'>
                      <input
                        type="name"
                        placeholder="Name"
                        required
                        className="bg-transparent w-[45%] max-md:w-full h-[60px] border border-gray-300 rounded-md px-4 my-4"
                      // value={name}
                      // onChange={(e) => setName(e.target.value)}
                      />
                      <input
                        type="phone"
                        placeholder="Phone"
                        required
                        className="bg-transparent w-[45%] max-md:w-full h-[60px] border border-gray-300 rounded-md px-4 my-4"
                      // value={phone}
                      // onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className='flex w-full max-md:flex-col justify-between'>
                      <input
                        type="restaurant"
                        placeholder="Restaurant Name (optional)"
                        className="bg-transparent w-[45%] max-md:w-full h-[60px] border border-gray-300 rounded-md px-4 my-4"
                      // value={restaurant}
                      // onChange={(e) => setRestaurant(e.target.value)}
                      />
                      <input
                        type="location"
                        placeholder="Location"
                        required
                        className="bg-transparent w-[45%] max-md:w-full h-[60px] border border-gray-300 rounded-md px-4 my-4"
                      // value={location}
                      // onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                    <textarea
                      type="message"
                      placeholder="Message"
                      required
                      className="flex bg-transparent w-full max-md:w-full min-h-[200px] border border-gray-300 rounded-md p-4"
                    // value={message}
                    // onChange={(e) => setMessage(e.target.value)}
                    />
                    <button className='flex cursor-pointer my-2 w-[188px] h-[50px] text-center rounded-[12px] border border-orange-500 hover:bg-orange-500 bg-[#E65302] p-2 text-white text-[12px] items-center justify-center'>Submit</button>
                  </div>
                </div>
                {/* <ContactForm /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='#' className='h-fit'>
        <div className='py-4 flex flex-col justify-center items-center h-full w-full bg-[#F4F4F4]'>
          <div className='flex flex-col w-[1080px] max-md:w-full max-md:px-6 justify-center'>
            <div className='font-bold text-7xl max-md:text-5xl my-3 mt-10'>Frequently Asked Question </div>
            <div className='font-normal text-2xl my-2'>Have a questions?  We’re here to help!</div>
            <div className='h-[1px] bg-slate-400 w-full my-5 mb-10' />
            <div className='flex w-full'>
              <FAQ />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default ContactUs
