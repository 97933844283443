import React from 'react';
import { logo } from './assets';

const Intro = () => {
  return (
    <div className='h-screen w-screen flex flex-col bg-white justify-center items-center'>
      <img src={logo} className='w-[100px]' alt='Tayari - Digital waiter' />
    </div>
  )
};

export default Intro;
