import React, { useRef, useEffect, useState } from 'react';
import { Restaurant1, Restaurant2, Restaurant3, Restaurant4, Restaurant5, Restaurant6, Restaurant7, Restaurant8 } from '../assets';

const RestaurantGallery = () => {
  const galleryRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const gallery = galleryRef.current;

    console.log('Gallery reference:', gallery); // Check if galleryRef is correctly referencing the element

    let scrollInterval;

    const startScrolling = () => {
      scrollInterval = setInterval(() => {
        gallery.scrollTo({
          left: gallery.scrollLeft + 1,
          behavior: 'smooth'
        });
      }, 30); // Adjust scroll speed (lower value = faster scroll)
    };

    const stopScrolling = () => {
      clearInterval(scrollInterval);
    };

    startScrolling();

    gallery.addEventListener('mouseenter', stopScrolling);
    gallery.addEventListener('mouseleave', startScrolling);

    return () => {
      clearInterval(scrollInterval);
      gallery.removeEventListener('mouseenter', stopScrolling);
      gallery.removeEventListener('mouseleave', startScrolling);
    };
  }, []);

  return (
    <div className="flex items-center justify-center overflow-x-auto scrollbar-hide">
      <div
        ref={galleryRef}
        className="flex gap-5"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{ cursor: isHovering ? 'default' : 'grab' }}
      >
        <img src={Restaurant1} alt="Restaurant 1" className="max-w-full h-auto cursor-pointer max-md:w-[120px] max-sm:w-[100px]" />
        <img src={Restaurant2} alt="Restaurant 2" className="max-w-full h-auto cursor-pointer max-md:w-[120px] max-sm:w-[100px]" />
        <img src={Restaurant3} alt="Restaurant 3" className="max-w-full h-auto cursor-pointer max-md:w-[120px] max-sm:w-[100px]" />
        <img src={Restaurant4} alt="Restaurant 4" className="max-w-full h-auto cursor-pointer max-md:w-[120px] max-sm:w-[100px]" />
        <img src={Restaurant5} alt="Restaurant 5" className="max-w-full h-auto cursor-pointer max-md:w-[120px] max-sm:w-[100px]" />
        <img src={Restaurant6} alt="Restaurant 6" className="max-w-full h-auto cursor-pointer max-md:w-[120px] max-sm:w-[100px]" />
        <img src={Restaurant7} alt="Restaurant 7" className="max-w-full h-auto cursor-pointer max-md:w-[120px] max-sm:w-[100px]" />
        <img src={Restaurant8} alt="Restaurant 8" className="max-w-full h-auto cursor-pointer max-md:w-[120px] max-sm:w-[100px]" />
      </div>
    </div>
  );
};

export default RestaurantGallery;
