import React from 'react';
import { blog1, blog2, blog3, blog4, blog5, blog6 } from '../assets';
import Footer from '../components/Footer';

const Blog = () => {
  return (
    <div className='h-full'>
      <section id='#' className='h-fit'>
        <div className='py-4 flex justify-center items-center'>
          <div className='flex flex-col w-[1080px] max-md:w-full max-md:px-6 items-center justify-between'>
            <div className='flex max-sm:flex-col w-full gap-5'>
              <div className='flex flex-col w-[70%] max-sm:w-full bg-[#E65300] rounded-xl'>
                <div className='rounded-[12px] text-white'>
                  <img src={blog1} className='object-cover shrink-0 rounded-t-[12px]' />
                  <div className='p-8'>
                    <div className='font-bold text-[45px] max-md:text-[25px] w-[85%] max-sm:w-full leading-10 max-md:leading-7'>Mbinu zitakazowasaidia wahudumu kuvuta wateja wengi mgahawani</div>
                    <div className='font-light text-[20px] max-md:text-[25px] w-[85%] max-sm:w-full mt-6 mb-6 max-md:leading-7'>Ukiwa mfanyabiashara au mfanyakazi suala la kula katika mgahawa nyakati za usiku au mchana ni la kawaida sana, lakini kumekuwa na tabia ya wauzaji wa chakula mgahawani kuwazoe wateja wao, jambo linaloathiri utoaji wa huduma husika......... <a href='' className='hover:underline' >Read more</a></div>
                    <div>
                      <div className='flex w-[188px] h-[51px] justify-center items-center cursor-pointer rounded-[12px] border text-[#042D5E] hover:border-orange-500 bg-[#E0E0E0] hover:bg-[#E65302] p-2 px-6 hover:text-white text-[14px]'>Read More</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col w-[30%] max-sm:w-full gap-4'>
                <div className='h-[400px] w-[320px] max-sm:w-full bg-white rounded-[12px]'>
                  <div className='h-[48%] overflow-hidden'>
                    <img src={blog2} className='object-cover max-sm:w-full shrink-0 rounded-t-[12px]' />
                  </div>
                  <div className='h-[52%] px-4 py-1 gap-2'>
                    <div className='py-2'>
                      <div className='text-[#1F2937] font-bold'>Card title</div>
                      <div className='text-[#6B7280] text-[12px] font-semibold'>CARD SUBTITLE</div>
                    </div>
                    <div className='text-[12px] text-[#6B7280]'>Some quick example text to build on the card title and make up the bulk of the card's content.</div>
                    <div className='flex py-2 gap-2'>
                      <div className='flex w-[100px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:bg-[#E0E0E0] hover:text-[#042D5E] bg-[#E65302] p-2 px-2 text-white text-[12px]'>Go somewhere</div>
                      <div className='flex w-[70px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:border-[#042D5E] bg-zinc-100 hover:bg-[#042D5E] p-2 px-2 hover:text-white text-[12px]'>Card link</div>
                    </div>
                  </div>
                </div>
                <div className='h-[400px] w-[320px] max-sm:w-full bg-white rounded-[12px]'>
                  <div className='h-[48%] overflow-hidden'>
                    <img src={blog3} className='object-cover max-sm:w-full shrink-0 rounded-t-[12px]' />
                  </div>
                  <div className='h-[52%] px-4 py-1 gap-2'>
                    <div className='py-2'>
                      <div className='text-[#1F2937] font-bold'>Card title</div>
                      <div className='text-[#6B7280] text-[12px] font-semibold'>CARD SUBTITLE</div>
                    </div>
                    <div className='text-[12px] text-[#6B7280]'>Some quick example text to build on the card title and make up the bulk of the card's content.</div>
                    <div className='flex py-2 gap-2'>
                      <div className='flex w-[100px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:bg-[#E0E0E0] hover:text-[#042D5E] bg-[#E65302] p-2 px-2 text-white text-[12px]'>Go somewhere</div>
                      <div className='flex w-[70px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:border-[#042D5E] bg-zinc-100 hover:bg-[#042D5E] p-2 px-2 hover:text-white text-[12px]'>Card link</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex w-full py-5 gap-4 overflow-x-auto scrollbar-hide'>
              <div className='h-[320px] w-[270px] bg-white rounded-[12px]'>
                <div className='h-[45%]'>
                  <img src={blog4} className='object-cover shrink-0 rounded-t-[12px]' />
                </div>
                <div className='h-[55%] px-4 py-1 gap-2'>
                  <div className='py-2'>
                    <div className='text-[#1F2937] font-bold'>Card title</div>
                    <div className='text-[#6B7280] text-[12px] font-semibold'>CARD SUBTITLE</div>
                  </div>
                  <div className='text-[12px] text-[#6B7280]'>Some quick example text to build on the card title and make up the bulk of the card's content.</div>
                  <div className='flex py-2 gap-2'>
                    <div className='flex w-[100px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:bg-[#E0E0E0] hover:text-[#042D5E] bg-[#E65302] p-2 px-2 text-white text-[12px]'>Go somewhere</div>
                    <div className='flex w-[70px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:border-[#042D5E] bg-zinc-100 hover:bg-[#042D5E] p-2 px-2 hover:text-white text-[12px]'>Card link</div>
                  </div>
                </div>
              </div>
              <div className='h-[320px] w-[270px] bg-white rounded-[12px]'>
                <div className='h-[45%]'>
                  <img src={blog5} className='object-cover shrink-0 rounded-t-[12px]' />
                </div>
                <div className='h-[55%] px-4 py-1 gap-2'>
                  <div className='py-2'>
                    <div className='text-[#1F2937] font-bold'>Card title</div>
                    <div className='text-[#6B7280] text-[12px] font-semibold'>CARD SUBTITLE</div>
                  </div>
                  <div className='text-[12px] text-[#6B7280]'>Some quick example text to build on the card title and make up the bulk of the card's content.</div>
                  <div className='flex py-2 gap-2'>
                    <div className='flex w-[100px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:bg-[#E0E0E0] hover:text-[#042D5E] bg-[#E65302] p-2 px-2 text-white text-[12px]'>Go somewhere</div>
                    <div className='flex w-[70px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:border-[#042D5E] bg-zinc-100 hover:bg-[#042D5E] p-2 px-2 hover:text-white text-[12px]'>Card link</div>
                  </div>
                </div>
              </div>
              <div className='h-[320px] w-[270px] bg-white rounded-[12px]'>
                <div className='h-[45%]'>
                  <img src={blog6} className='object-cover shrink-0 rounded-t-[12px]' />
                </div>
                <div className='h-[55%] px-4 py-1 gap-2'>
                  <div className='py-2'>
                    <div className='text-[#1F2937] font-bold'>Card title</div>
                    <div className='text-[#6B7280] text-[12px] font-semibold'>CARD SUBTITLE</div>
                  </div>
                  <div className='text-[12px] text-[#6B7280]'>Some quick example text to build on the card title and make up the bulk of the card's content.</div>
                  <div className='flex py-2 gap-2'>
                    <div className='flex w-[100px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:bg-[#E0E0E0] hover:text-[#042D5E] bg-[#E65302] p-2 px-2 text-white text-[12px]'>Go somewhere</div>
                    <div className='flex w-[70px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:border-[#042D5E] bg-zinc-100 hover:bg-[#042D5E] p-2 px-2 hover:text-white text-[12px]'>Card link</div>
                  </div>
                </div>
              </div>
              <div className='h-[320px] w-[270px] bg-white rounded-[12px]'>
                <div className='h-[45%]'>
                  <img src={blog6} className='object-cover shrink-0 rounded-t-[12px]' />
                </div>
                <div className='h-[55%] px-4 py-1 gap-2'>
                  <div className='py-2'>
                    <div className='text-[#1F2937] font-bold'>Card title</div>
                    <div className='text-[#6B7280] text-[12px] font-semibold'>CARD SUBTITLE</div>
                  </div>
                  <div className='text-[12px] text-[#6B7280]'>Some quick example text to build on the card title and make up the bulk of the card's content.</div>
                  <div className='flex py-2 gap-2'>
                    <div className='flex w-[100px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:bg-[#E0E0E0] hover:text-[#042D5E] bg-[#E65302] p-2 px-2 text-white text-[12px]'>Go somewhere</div>
                    <div className='flex w-[70px] h-[38px] justify-center items-center cursor-pointer rounded-[10px] border hover:border-[#042D5E] bg-zinc-100 hover:bg-[#042D5E] p-2 px-2 hover:text-white text-[12px]'>Card link</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
};

export default Blog;