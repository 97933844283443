import React from 'react'
import { pointofsale, problem, solution, video } from '../assets';
import VideoPlayer from '../components/VideoPlayer';
import Footer from '../components/Footer';

const OurStory = () => {
  return (
    <div className='h-full'>
      <section id='#about-us' className='h-fit'>
        <div className='py-4 flex justify-center items-center'>
          <div className='flex w-[1080px] max-md:w-full max-md:px-6 items-center justify-between'>
            <div className='flex flex-col w-full'>
              <div className='font-bold text-7xl max-sm:text-5xl my-3'>About Tayari</div>
              <div className='my-3 w-[50%] max-md:w-full'>
                <div className='font-normal text-xl'>It is OK to wait for your chances in life, but food?</div>
                <div className='font-normal text-xl'>Food should come a little bit faster to my table!</div>
              </div>
              <div className='flex flex-col my-3 gap-5 font-normal text-justify'>
                <div className=''>In an era where technology has transformed the way we live, work, and communicate, there's one aspect of our daily lives that has been longing for a digital revolution – dining. Imagine a dining experience where waiting endlessly for a menu, struggling to understand complex dishes, and chasing down elusive waiters are relics of the past. This vision sparked the creation of TAYARI Digital Waiter.</div>
                <div className=''>TAYARI, which means 'ready' in Swahili, is more than just a name; it's a promise. A promise to make dining out a seamless, enjoyable, and convenient experience for all. Our journey began with a simple observation during a family dinner at a restaurant in Dar es Salaam.</div>
                <div className=''>The frustrations we faced that evening - the lengthy wait times, menu mysteries, and payment hassles - echoed the sentiments of countless diners across Tanzania.</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='#our-mission' className='h-fit flex justify-center items-center'>
        <VideoPlayer />
      </section>
      <section id='#innovation' className='h-fit'>
        <div className='px-10 max-md:px-6 py-4 flex flex-col justify-center items-center'>
          <div className='flex max-sm:flex-col flex-shrink-0 w-[1080px] max-md:w-full gap-5 mt-5 items-start justify-between'>
            <div className='flex flex-col w-[50%] max-sm:w-full'>
              <img src={problem} className='shrink-0 resize-none h-full w-full' />
              <div className='font-bold text-[30px] mt-2'>What is the problem?</div>
              <div className='flex flex-col my-3 mt-0 gap-5 font-normal text-justify'>
                <div className=''>Imagine visiting a fine restaurant after a long working day or just to please yourself with some great food your pocket can buy only to experience the following;</div>
                <div className=''>You struggle to find the waiter because they are busy with other customers or delivering orders so you try to call for a waiter hoping one will hear you and come to your table!</div>
                <div className=''>Finally, you find the waiter he comes to you and ask you what you would like to eat? You request for the menu, and the waiter has to go back to fetch the menu.</div>
                <div className=''>You have the menu and still, can't decide because you don't understand the names and descriptions (Of, course they are written in English 😂!).</div>
                <div className=''>You kinda found a meal item you would like to have, to be sure with the selection, you ask the waiter for some extra details, they try to explain and you try to understand!</div>
                <div className=''>Finally, you place the order and you have to sit there waiting for your food. Normally it takes about 30-50 minutes to wait for your order</div>
                <div className=''>You sit there watching waiters delivering orders to other tables while you play the wish game that it's your turn because you just want to have your meal and you are hungry, but well...</div>
                <div className=''>The food is finally delivered to your table and you get to have the best time devouring your plate. For a couple of minutes, the World stops</div>
                <div className=''>Then you are done and now you have to pay! And the struggle continues!</div>
                <div className=''>This is the problem that excites us and we would like to provide fellow dinners with a better experience at restaurants and dining places in Tanzania and later other African countries facing similar challenges!</div>
              </div>
            </div>
            <div className='flex flex-col w-[50%] max-sm:w-full'>
              <img src={solution} className='shrink-0 resize-none h-full w-full' />
              <div className='font-bold text-[30px] mt-2'>So what is our Solution?</div>
              <div className='flex flex-col my-3 mt-0 gap-5 font-normal text-justify'>
                <div className=''>TAYARI provides a full point of sale system that also works as a digital waiter helping customers find updated, easy to read menus from various restaurants.</div>
                <div className=''>Our digital waiter also allows the restaurant customer to order, reserve and pay straight from their smartphones. While providing the restaurant with information that increases customers' service provided based on spending habits, restaurants can improve their menu, provide discounts and deals to loyal customers and increase sales, restaurants can also learn the best food combo per diners category, time, day and per spending habits.</div>
                <div className=''>We think the time has come for restaurant menus to be easily accessible to customers.</div>
                <div className=''>In most scenarios, customers tend to have a wish for either a restaurant or a meal they would like to have before visiting a restaurant. And we are placing ourselves at a point where we can help dinners move from a wishful state and help them come up with a clear plan of what they would like to have either for themselves or for friends and family when they visit a restaurant.</div>
                <div className=''>Imagine Being able to check a restaurant menu and place an order from anywhere whether on a break, stuck in traffic, or riding the bus quickly and painlessly. And being able to understand how long your order will take until it's ready so that you plan your visit better with family or friends. And how amazing it feels to arrive and to have your order ready waiting for you.</div>
              </div>
            </div>
          </div>
          <div className='flex w-[1080px] max-md:w-full font-medium my-5'>We would like to invite you to use our application and get to know your customers and why they love coming to your restaurants. We will provide you with insights and integrations that make the order management process a less painful task or a hurdle for your restaurant's growth.</div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default OurStory;