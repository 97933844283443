import React, { useState } from 'react';
import { Facebook, Instagram, LinkedIn, logo, X, Medium } from '../assets';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { navLinks } from './Navbar';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(getActiveLink(location.pathname));

  function getActiveLink(pathname) {
    const activeLink = navLinks.find(item => `/${item.id}` === pathname);
    return activeLink ? activeLink.title : "Dashboard";
  }

  return (
    <div className='flex gap-4 p-2 bg-black text-white px-4 justify-center'>
      <div className='flex flex-col w-[1080px] max-md:w-full max-md:px-3 items-center justify-between'>
        <div className='flex w-full justify-center'>
          <div className='flex flex-col my-6 w-[50%] justify-start'>
            <div className='text-[35px] max-sm:text-[25px] font-light w-[450px]'>
              One stop solution for all order management solution
            </div>
            <div className='cursor-pointer my-4 w-[120px] h-[40px] text-center rounded-[12px] border border-orange-500 hover:bg-orange-500 bg-[#E65302] p-2 px-6 text-white text-[12px] items-center justify-center'>Contact us</div>
          </div>
          <div className='flex w-[50%]' />
        </div>
        <div className='h-[1px] w-full my-2 bg-white' />
        <div className='flex w-full justify-between'>
          <div className='flex max-sm:flex-col max-sm:grid max-sm:grid-cols-2 w-full gap-[10px] justify-between py-4 pb-6'>
            <div>
              <div className='text-[25px] font-bold'>OurStory</div>
              <div>
                <div>
                  <Link to={`/our-story/#innovation`}>
                    <div className='text-[#828282] text-left text-[15px] hover:text-slate-300 cursor-pointer'>Problem</div>
                  </Link>
                </div>
                <Link to={`/our-story/#innovation`}>
                  <div className='text-[#828282] text-left text-[15px] hover:text-slate-300 cursor-pointer'>Solution</div>
                </Link>
              </div>
            </div>
            <div>
              <div className='text-[25px] font-bold'>Product</div>
              <div>
                <Link to={`/#digital waiter`}>
                  <div className='text-[#828282] text-left text-[15px] hover:text-slate-300 cursor-pointer'>Digital Waiter</div>
                </Link>
                <Link to={`/#point of sale`}>
                  <div className='text-[#828282] text-left text-[15px] hover:text-slate-300 cursor-pointer'>Point of sale</div>
                </Link>
                <Link to={`/#inventory management`}>
                  <div className='text-[#828282] text-left text-[15px] hover:text-slate-300 cursor-pointer'>Inventory Management</div>
                </Link>
              </div>
            </div>
            <div>
              <div className='text-[25px] font-bold'>Pricing</div>
              <div>
                <Link to={`/`}>
                  <div className='text-[#828282] text-left text-[15px] hover:text-slate-300 cursor-pointer'>TayariStart</div>
                </Link>
                <Link to={`/`}>
                  <div className='text-[#828282] text-left text-[15px] hover:text-slate-300 cursor-pointer'>TayariGrow</div>
                </Link>
                <Link to={`/`}>
                  <div className='text-[#828282] text-left text-[15px] hover:text-slate-300 cursor-pointer'>TayariPRO</div>
                </Link>
              </div>
            </div>
            <div>
              <div className='text-[25px] font-bold'>Quick Links</div>
              <div>
                {navLinks.map((item, index) => (
                  <Link to={`/${item.id}`} key={index} onClick={() => setActive(item.name)} className='flex gap-10'>
                    <div className={`text-[15px] ${active === item.name ? "text-orange-500 font-bold" : "text-[#828282] hover:text-slate-300"}`}>{item.name}</div>
                  </Link>
                ))}
              </div>
            </div>
            <div className='m-2'>
              {/* <Link to={`/`} className='cursor-pointer'>
                <img src={logo} className='w-[50px]' />
              </Link> */}
              <div className='flex gap-2'>
                <div>FollowUs: </div>
                <div className='flex gap-2 justify-center items-center'>
                  <a href='https://x.com/tayari_exp' target='_blank'>
                    <img src={X} className='shrink-0 hover:bg-orange-500 p-1 rounded-lg' />
                  </a>
                  <a href='https://instagram.com/tayari_experience' target='_blank'>
                    <img src={Instagram} className='shrink-0 hover:bg-orange-500 p-1 rounded-lg' />
                  </a>
                  <a href='https://linkedin.com/company/tayari-experience' target='_blank'>
                    <img src={LinkedIn} className='shrink-0 hover:bg-orange-500 p-1 rounded-lg' />
                  </a>
                  <a href='https://medium.com/@TAYARI-News' target='_blank'>
                    <img src={Medium} className='shrink-0 hover:bg-orange-500 p-1 rounded-lg' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='h-[1px] w-full my-2 bg-white' />
        <div className='flex max-sm:flex-col-reverse w-full max-sm:justify-center max-sm:items-center gap-4 mb-10 mt-4'>
          <div className='w-[60%] max-sm:w-full max-sm:text-center'>© 2024 Tayari, Inc. All rights reserved</div>
          <div className='flex w-[35%] max-sm:w-full justify-between max-sm:justify-around gap-4'>
            <Link to={`/terms-and-conditions`} className='hover:text-blue-500'>Terms & Conditions</Link>
            <Link to={`/privacy-policy`} className='hover:text-blue-500'>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;