import { useState } from 'react';

const DeviceTypeButton = () => {
    const [deviceType, setDeviceType] = useState('');

    const handleButtonClick = () => {
        // Detect the user's device type
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // Redirect for Android devices
            window.location.href = 'https://play.google.com/store/apps/details?id=com.tayari.client.tayari&hl=en&gl=US';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Redirect for iOS devices
            window.location.href = 'https://apps.apple.com/tz/app/tayari-experience/id1621925001';
        } else {
            // Redirect for desktop/laptop (Mac or other)
            window.location.href = 'https://order.tayari.co.tz';
        }
    };

    return (
        <div
            className='cursor-pointer rounded-[12px] border border-orange-500 hover:bg-orange-500 bg-[#E65302] p-2 px-6 text-white text-[12px]'
            onClick={handleButtonClick}
        >
            Try Tayari Now!
        </div>
    );
};

export default DeviceTypeButton;