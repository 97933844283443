import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion"
import { smilewaiter, smilemalewaiter, cashier, pointofsale, inventorymanagement, digitalwaiter, restaurants, Restaurant1, Restaurant2, Restaurant3, Restaurant4, Restaurant5, Restaurant6, Restaurant7, Restaurant8 } from '../assets';
import { Link } from 'react-router-dom';
import RestaurantGallery from '../components/RestaurantGallery';
import Footer from '../components/Footer';

const Home = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const images = [smilewaiter, smilemalewaiter, cashier];
  const titles = [
    'For a great experience',
    'Empowering Restaurants',
    'Order Food with Ease'
  ];
  const subtitles = [
    'One stop solution for all order management solution',
    'Helping restaurants manage, monitor, and operate their business from start to finish',
    'Our app provides an easy and convenient way for end users to order food from your favorite restaurants'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment slider index cyclically
      setSliderIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleSliderChange = (index) => {
    setSliderIndex(index);
  };

  return (
    <div className='h-full'>
      <section id='#hero' className='h-full'>
        <div className='relative h-full'>
          <img
            src={images[sliderIndex]}
            className='inset-0 w-full h-full object-cover transition-opacity duration-500'
            style={{ opacity: 1 }}
          />
          <div className='flex absolute bottom-0 inset-0 bg-gradient-to-t from-[#000000b3] from-1%' />
          <div className='flex w-[1080px] items-center justify-between'>

            <div className='absolute bottom-10 left-0 right-0 text-white text-center gap-4 justify-center items-center transition-opacity duration-500'>
              <div className='font-semibold text-7xl m-4'>{titles[sliderIndex]}</div>
              <div className='font-light text-xl m-2'>{subtitles[sliderIndex]}</div>
              {/* Slider change indicator */}
              <div className='flex gap-2 justify-center items-center mt-4'>
                {images.map((_, index) => (
                  <div
                    key={index}
                    className={`rounded-full ${sliderIndex === index ? 'bg-orange-600' : 'bg-gray-200'} shadow-lg p-[6px] m-[2px] cursor-pointer`}
                    onClick={() => handleSliderChange(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='#point of sale' className='h-fit'>
        <div className='max-md:px-4 px-10 py-4 flex justify-center items-center'>
          <div className='flex max-sm:flex-col-reverse w-[1080px] max-md:w-full items-center justify-between'>
            <div className='flex flex-col w-[50%] max-md:w-full p-10 max-md:p-1'>
              <div className='font-bold text-7xl max-md:text-4xl m-3 max-md:m-1'>Point of sale</div>
              <div className='font-normal text-xl max-md:text-lg m-3 max-md:m-1'>TAYARI provides restaurant with a POS system that helps restaurants manage their most important aspect of their business.</div>
              <div className='flex gap-5 mx-3 max-md:mx-1'>
                <Link to={`/pricing`} className='flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-orange-500 hover:bg-orange-500 bg-[#E65302] p-2 text-white text-[12px] max-md:text-[10px] items-center justify-center'>Compare Pricing Plans</Link>
                <a href='https://pos.tayari.co.tz' target='_blank' className='flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-[#BDBDBD] hover:bg-zinc-200 bg-[#E0E0E0] p-2 text-[12px] max-md:text-[10px] items-center justify-center'>Try Tayari Now!</a>
              </div>
            </div>
            <div className='flex w-[50%] max-sm:w-full flex-shrink-0'>
              <img src={pointofsale} className='shrink-0 resize-none h-full w-full' />
            </div>
          </div>
        </div>
      </section>
      <section id='#inventory management' className='h-fit'>
        <div className='max-md:px-4 px-10 py-4 flex justify-center items-center'>
          <div className='flex max-sm:flex-col w-[1080px] max-md:w-full items-center justify-between'>
            <div className='flex w-[50%] max-sm:w-full flex-shrink-0'>
              <img src={inventorymanagement} className='shrink-0 resize-none h-full w-[656px]' />
            </div>
            <div className='flex flex-col w-[50%] max-md:w-full p-10 max-md:p-1'>
              <div className='font-bold text-7xl max-md:text-4xl m-3 max-md:m-1'>Inventory Management</div>
              <div className='font-normal text-xl max-md:text-lg m-3 max-md:m-1'>Simplify the process of ordering, storing, and using inventory by automating end-to-end production, business management, demand forecasting, and accounting.</div>
              <div className='flex gap-5 mx-3 max-md:mx-1'>
                <Link to={`/pricing`} className='flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-orange-500 hover:bg-orange-500 bg-[#E65302] p-2 text-white text-[12px] max-md:text-[10px] items-center justify-center'>Compare Pricing Plans</Link>
                <a href='https://restaurants.tayari.co.tz' target='_blank' className='flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-[#BDBDBD] hover:bg-zinc-200 bg-[#E0E0E0] p-2 text-[12px] max-md:text-[10px] items-center justify-center'>Try Tayari Now!</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='#digital waiter' className='h-fit'>
        <div className='max-md:px-4 px-10 py-4 flex justify-center items-center'>
          <div className='flex max-sm:flex-col w-[1080px] max-md:w-full items-center justify-between'>
            <div className='flex w-[50%] max-sm:w-full flex-shrink-0'>
              <img src={digitalwaiter} className='shrink-0 resize-none h-full w-full' />
            </div>
            <div className='flex flex-col w-[50%] max-md:w-full p-10 max-md:p-1'>
              <div className='font-bold text-7xl max-md:text-4xl m-3 max-md:m-1'>Digital Waiter</div>
              <div className='font-bold text-xl ml-1 mt-3'>Your Digital Waiter is Here!</div>
              <div className='font-normal text-xl max-md:text-lg m-3 max-md:m-1'>We have created a digital waiter that can take orders and receive payments from customers</div>
              <div className='flex gap-5 mx-3 max-md:mx-1'>
                <Link to={`/pricing`} className='flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-orange-500 hover:bg-orange-500 bg-[#E65302] p-2 text-white text-[12px] max-md:text-[10px] items-center justify-center'>Compare Pricing Plans</Link>
                <a href='https://order.tayari.co.tz' target='_blank' className='flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-[#BDBDBD] hover:bg-zinc-200 bg-[#E0E0E0] p-2 text-[12px] max-md:text-[10px] items-center justify-center'>Try Tayari Now!</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='#trusted by these merchants' className='h-fit'>
        <div className='px-10 max-sm:px-5 py-4 flex flex-col justify-center items-center'>
          <div className='flex flex-col w-[1080px] max-sm:w-full '>
            <div className='font-bold text-7xl my-3 w-[55%] max-md:w-full max-md:text-6xl max-sm:text-5xl'>Trusted by these merchants</div>
          </div>
          <div className='flex gap-5 my-10 mb-20 w-[1080px] max-md:w-full overflow-x-hidden justify-center items-center'>
            <RestaurantGallery />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;