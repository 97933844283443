import React from 'react';
import { logo } from './assets';

const ErrorBoundary = () => {
  return (
    <div className='flex flex-col gap-2 w-full h-screen text-center items-center justify-center text-white bg-black'>
      <img src={logo} className='w-[300px]' />
      <div className='font-bold text-[30px]'>Error, 404!</div>
      <div className='font-semibold text-[20px] text-red-500'>Not Found</div>
    </div>
  )
};

export default ErrorBoundary;