import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='h-full'>
      <section id='#' className='h-fit'>
        <div className='py-4 flex justify-center items-center'>
          <div className='flex w-[1080px] max-md:w-full max-md:px-6 items-center justify-between'>
            <div className='flex flex-col w-full'>
              <div className='font-bold text-7xl max-sm:text-5xl my-3'>Privacy Policy</div>
              <>
                <div className='font-bold text-xl mt-5'>Privacy Policy</div>
                <div className='flex flex-col my-3 gap-5 font-normal text-justify'>
                  <div className=''>TAYARI PAYMENTS COMPANY owner of “TAYARI” (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Application”). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.</div>
                  <div className=''>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.</div>
                  <div className=''>This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party.</div>
                </div>
              </>
              <>
                <div className='font-bold text-xl mt-5'>COLLECTION OF YOUR INFORMATION</div>
                <div className='flex flex-col my-3 gap-2 mb-5 font-normal text-justify'>
                  <div className=''>We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use and includes:</div>
                  <div className=''>
                    <div className='font-semibold'>Personal Data</div>
                    <div className=''>Demographic and other personally identifiable information (such as your name and email address) that you voluntarily give to us when choosing to participate in various activities related to the Application, such as chat, posting messages in comment sections or in our forums, liking posts, sending feedback, and responding to surveys. If you choose to share data about yourself via your profile, online chat, or other interactive areas of the Application, please be advised that all data you disclose in these areas is public and your data will be accessible to anyone who accesses the Application.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Derivative Data</div>
                    <div className=''>The information our servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including liking, re-blogging, or replying to a post, as well as other interactions with the Application and other users via server log files.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Financial Data</div>
                    <div className=''>Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Application. We store only very limited, if any, financial information that we collect.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Facebook Permissions</div>
                    <div className=''>The Application may by default access your Facebook basic account information, including your name, email, gender, birthday, current city, and profile picture URL, as well as other information that you choose to make public. We may also request access to other permissions related to your accounts, such as friends, check-ins, and likes, and you may choose to grant or deny us access to each individual permission. For more information regarding Facebook permissions, refer to the Facebook Permissions Reference page.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Data from Social Networks</div>
                    <div className=''>User information from social networking sites, such as Facebook and Twitter will include your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts if you connect your account to such social networks. This information may also include the contact information of anyone you invite to use and/or join the Application.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Geo-Location Information</div>
                    <div className=''>We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Mobile Device Access</div>
                    <div className=''>We will inform you when the application demands any access from your mobile phone, at the moment we don’t have features that demand device access.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Mobile Device Data</div>
                    <div className=''>Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Push Notifications</div>
                    <div className=''>We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Third-Party Data</div>
                    <div className=''>Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Application permission to access this information.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Data From Contests, Giveaways, and Surveys</div>
                    <div className=''>Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.</div>
                  </div>
                </div>
              </>
              <>
                <div className='font-bold text-xl mt-5'>USE OF YOUR INFORMATION</div>
                <div className='flex flex-col my-3 gap-0 font-normal text-justify'>
                  <div className=''>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to:</div>
                  <ol type="1" className='ml-7' style={{ listStyleType: 'decimal' }}>
                    <li className=''>Enable user-to-user communications.</li>
                    <li className=''>Create and manage your account.</li>
                    <li className=''>Generate a personal profile about you to make future visits to the Application more personalized.</li>
                    <li className=''>Email you regarding your account or order.</li>
                    <li className=''>Administer promotions.</li>
                    <li className=''>Fulfil and manage purchases, orders, payments, and other transactions related to the Application.</li>
                    <li className=''>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Application to you.</li>
                    <li className=''>Increase the efficiency and operation of the Application.</li>
                    <li className=''>Monitor and analyze usage and trends to improve your experience with the Application.</li>
                    <li className=''>Notify you of updates to the Application.</li>
                    <li className=''>Offer new products, services, mobile applications, and/or recommendations to you.</li>
                    <li className=''>Perform other business activities as needed.</li>
                    <li className=''>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
                    <li className=''>Process payments and refunds.</li>
                    <li className=''>Request feedback and contact you about your use of the Application.</li>
                    <li className=''>Resolve disputes and troubleshoot problems.</li>
                    <li className=''>Respond to product and customer service requests.</li>
                    <li className=''>Send you a newsletter.</li>
                    <li className=''>Solicit support for the Application.</li>
                    <li className=''>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
                  </ol>
                </div>
              </>
              <>
                <div className='font-bold text-xl mt-5'>DISCLOSURE OF YOUR INFORMATION</div>
                <div className='flex flex-col my-3 gap-2 mb-5 font-normal text-justify'>
                  <div className=''>We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use and includes:</div>
                  <div className=''>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.</div>
                  <div className=''>
                    <div className='font-semibold'>Third-Party Service Providers</div>
                    <div className=''>We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Marketing Communications</div>
                    <div className=''>With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Interactions with Other Users</div>
                    <div className=''>If you interact with other users of the Application, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Online Postings</div>
                    <div className=''>When you post comments, contributions or other content to the Applications, your posts may be viewed by all users and may be publicly distributed outside the Application in perpetuity.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Third-Party Advertisers</div>
                    <div className=''>We may use third-party advertising companies to serve ads when you visit the Application. These companies may use information about your visits to the Application and other websites that are contained in web cookies in order to provide advertisements about goods and services of interest to you.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Affiliates</div>
                    <div className=''>We may share your information with our affiliates, in which case we will require those affiliates to honour this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Business Partners</div>
                    <div className=''>We may share your information with our business partners to offer you certain products, services or promotions.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Offer Wall</div>
                    <div className=''>The Application may display a third-party-hosted “offer wall.” Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for acceptance and completion of an advertisement offer. Such an offer wall may appear in the Application and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will leave the Application. A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Social Media Contacts</div>
                    <div className=''>If you connect to the Application through a social network, your contacts on the social network will see your name, profile photo, and descriptions of your activity.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Other Third Parties</div>
                    <div className=''>We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>Sale or Bankruptcy</div>
                    <div className=''>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline honour commitments we made in this Privacy Policy.</div>
                  </div>
                  <div className='font-bold'>We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.</div>
                </div>
              </>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PrivacyPolicy
