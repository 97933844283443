import React, { useState } from 'react';
import { logo, close, menu } from '../assets';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DeviceTypeButton from './DeviceTypeButton';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(getActiveLink(location.pathname));
  const [toggle, setToggle] = useState(false);

  function getActiveLink(pathname) {
    const activeLink = navLinks.find(item => `/${item.id}` === pathname);
    return activeLink ? activeLink.title : "Dashboard";
  }

  return (
    <div className='sticky w-screen flex gap-4 p-2 bg-white h-[100px] px-4 justify-center'>
      <div className='flex w-[1080px] max-md:w-full items-center justify-between'>
        <Link to={`/`} className='cursor-pointer'>
          <img src={logo} />
        </Link>
        <div className='flex gap-14 items-center max-md:hidden'>
          {navLinks.map((item, index) => (
            <Link to={`/${item.id}`} key={index} onClick={() => setActive(item.name)} className='flex gap-10'>
              <div className={`${active === item.name ? "text-orange-500 font-bold" : "text-slate-500 font-normal hover:text-[#042d5e]"}`}>{item.name}</div>
            </Link>
          ))}
          <DeviceTypeButton />
        </div>

        <div className="md:hidden flex justify-end items-center">
          {toggle && <div className="mx-2"><DeviceTypeButton /></div>}
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain cursor-pointer"
            onClick={() => setToggle(!toggle)}
          />
          <div
            style={{ zIndex: 1 }}
            className={`bg-white p-4 mt-[30px] shadow-xl ${!toggle ? "hidden" : "flex"
              } p-2 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar md:hidden`}
          >
            <div className="flex justify-end items-start flex-1 flex-col">
              {navLinks.map((item, index) => (
                <Link to={`/${item.id}`} key={index} onClick={() => setActive(item.name)} className='flex gap-10'>
                  <div className={`${active === item.name ? "text-orange-500 font-bold" : "text-slate-500 font-normal hover:text-[#042d5e]"}`}>{item.name}</div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export const navLinks = [
  {
    id: "",
    name: "Home",
    title: "Home",
  },
  // {
  //   id: "our-products",
  //   name: "Our Products",
  //   title: "Our Products",
  // },
  {
    id: "our-story",
    name: "Our Story",
    title: "Our Story",
  },
  {
    id: "pricing",
    name: "Pricing",
    title: "Pricing",
  },
  {
    id: "blog",
    name: "Blog",
    title: "Blog",
  },
  {
    id: "contact-us",
    name: "Contact Us",
    title: "Contact Us",
  }
]

export default Navbar;
