import React, { useState } from "react";
import "./styles.css";

export default function FAQ() {
    const [faqs, setFaqs] = useState([
        {
            question: "What is Tayari for Restaurants?",
            answer: [
                "In an era where technology has transformed the way we live, work, and communicate, there's one aspect of our daily lives that has been longing for a digital revolution – dining. Imagine a dining experience where waiting endlessly for a menu, struggling to understand complex dishes, and chasing down elusive waiters are relics of the past. This vision sparked the creation of TAYARI Digital Waiter.",
                "TAYARI, which means 'ready' in Swahili, is more than just a name; it's a promise. A promise to make dining out a seamless, enjoyable, and convenient experience for all. Our journey began with a simple observation during a family dinner at a restaurant in Dar es Salaam.",
                "The frustrations we faced that evening - the lengthy wait times, menu mysteries, and payment hassles - echoed the sentiments of countless diners across Tanzania.",
            ],
            open: false
        },
        {
            question: "What’s involved in switching from my current solution to Tayari?",
            answer: ["Switching to Tayari involves a few steps: assess your current setup, migrate data, train staff on the new system, and ensure a smooth transition to minimize downtime."],
            open: false
        },
        {
            question: "What’s the difference between Tayari for Restaurants and Tayari Point of Sale?",
            answer: ["Tayari for Restaurants focuses on enhancing the dining experience with features like digital menus and customer engagement tools. Tayari Point of Sale (POS) is a comprehensive system tailored for restaurant management, including order management, inventory control, and analytics."],
            open: false
        },
        {
            question: "What’s involved in switching to the Restaurant POS?",
            answer: ["Switching to Tayari Restaurant POS involves assessing your needs, setting up the system, migrating data, training staff, and ensuring compatibility with your existing processes."],
            open: false
        },
        {
            question: "How can I upgrade or downgrade my Square for Restaurants software subscription plan?",
            answer: ["You can manage your Square for Restaurants subscription plan directly through the Square Dashboard. Navigate to the 'Subscription' section to view and adjust your plan."],
            open: false
        },
        {
            question: "How long will it take to get started with Tayari for Restaurants?",
            answer: ["The time to get started with Tayari depends on factors like your restaurant's size, existing infrastructure, and staff readiness. Typically, setup and training can be completed within a few days to a couple of weeks."],
            open: false
        },
    ]);

    const toggleFAQ = index => {
        setFaqs(prevFaqs =>
            prevFaqs.map((faq, i) => ({
                ...faq,
                open: i === index ? !faq.open : false
            }))
        );
    };

    const FAQUI = ({ faq, index }) => {
        return (
            <div
                className={"faq " + (faq.open ? "open" : "")}
                key={index}
                onClick={() => toggleFAQ(index)}
            >
                <div className="faq-question">{faq.question}</div>
                <div className="faq-answer">
                    {faq.answer.map((answer, idx) => (
                        <p key={idx} className="my-4">{answer}</p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="App">
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <FAQUI faq={faq} index={index} key={index} />
                ))}
            </div>
        </div>
    );
}
