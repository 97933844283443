import React, { useState } from 'react';
import { video } from '../assets';

const VideoPlayer = () => {
    const [showVideo, setShowVideo] = useState(false);
    const [thumbnair, setThumbnair] = useState(true);

    const handleImageClick = () => {
        setShowVideo(true);
        setThumbnair(false);
    };

    return (
        <div className={`relative w-[1080px] h-[680px] max-sm:w-full max-md:px-[10px] max-md:h-[600px] max-sm:h-[260px] bg-zinc-300 ${!showVideo && !thumbnair && 'animate-pulse'}`}>
            {!showVideo && (
                <img
                    src={video}
                    alt="Video Thumbnail"
                    className={`w-[1080px] h-[680px] max-sm:w-full max-md:px-[10px] max-md:h-[600px] max-sm:h-[260px] cursor-pointer ${!showVideo && !thumbnair && 'animate-pulse'}`}
                    onClick={handleImageClick}
                />
            )}
            {showVideo && (
                <iframe
                    className={`w-[1080px] h-[680px] max-sm:w-full max-md:px-[10px] max-md:h-[600px] max-sm:h-[260px]`}
                    src="https://www.youtube.com/embed/iEf8vois-mA?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            )}
        </div>
    );
};

export default VideoPlayer;